import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Servicios| Evergreen Hills Golf Club
			</title>
			<meta name={"description"} content={"El paraíso del golfista le espera"} />
			<meta property={"og:title"} content={"Servicios| Evergreen Hills Golf Club"} />
			<meta property={"og:description"} content={"El paraíso del golfista le espera"} />
			<meta property={"og:image"} content={"https://quixoticstories.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quixoticstories.com/img/2076961.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quixoticstories.com/img/2076961.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quixoticstories.com/img/2076961.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quixoticstories.com/img/2076961.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quixoticstories.com/img/2076961.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quixoticstories.com/img/2076961.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-primary" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h1"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Bienvenido a los servicios de Evergreen Hills Golf Club
						</Text>
						<Text as="p" margin="12px 0" font="--lead" md-font="--headline3">
							En Evergreen Hills Golf Club, nos enorgullecemos de ofrecer una amplia gama de servicios de primera calidad diseñados para mejorar su experiencia de golf. Nuestro compromiso con la excelencia garantiza que cada visita a nuestro club sea memorable, tanto si está aquí para jugar una ronda de golf, mejorar sus habilidades o simplemente relajarse en nuestras lujosas instalaciones. Descubra los excepcionales servicios que hacen de Evergreen Hills el destino definitivo para los entusiastas del golf.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://quixoticstories.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://quixoticstories.com/img/5.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://quixoticstories.com/img/6.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Servicios de lujo
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
					Elegante salón: Relájese en nuestro elegante salón, con cómodos asientos e impresionantes vistas del campo.
						<br />
						<br />
    Restaurante Gourmet: Saboree deliciosos platos elaborados por nuestros expertos chefs, con un menú que celebra la variedad de sabores.
						<br />
						<br />
    Espacios para eventos: Celebre sus eventos especiales en nuestros versátiles espacios, perfectos para bodas, reuniones de empresa y celebraciones sociales.
						<br />
						<br />
Tienda de golf: Nuestra tienda totalmente equipada ofrece una amplia selección de equipos de golf, ropa y accesorios de las mejores marcas.
						<br />
						<br />
    Personal experto: Nuestro personal experto está a su disposición para ayudarle con todas sus necesidades golfísticas, ofreciéndole recomendaciones personalizadas.
						<br />
						<br />
    Ajustes personalizados: Mejore su juego con ajustes de palos personalizados y asesoramiento profesional adaptado a su swing.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
					Entrenamiento experto
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						color="#555a5f"
						sm-margin="0px 0px 50px 0px"
						lg-margin="0px 0px 50px 0px"
					>
						Mejore su juego con nuestros completos servicios de entrenamiento y formación, diseñados para ayudar a golfistas de todos los niveles a alcanzar su máximo potencial.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Üyelik Paketleri
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Clases particulares: Reciba clases individuales de nuestros experimentados profesionales del golf, centradas en estrategias de mejora personalizadas.
						<br />
						<br />
    Técnicas avanzadas: Aprende técnicas y estrategias avanzadas para perfeccionar tu juego y bajar tus puntuaciones.
						<br />
						<br />
    Análisis de vídeo: Benefíciese del análisis del swing en vídeo para identificar las áreas de mejora y realizar un seguimiento de su progreso.
						<br />
						<br />
Clínics en grupo: Participa en clínicas de grupo que ofrecen un entorno de aprendizaje social e interactivo.
						<br />
						<br />
    Clínics para principiantes: Perfectos para nuevos golfistas, nuestros cursos para principiantes cubren los fundamentos del juego en un entorno de apoyo.
						<br />
						<br />
    Clínics avanzados: Desafíate a ti mismo con clínics avanzados que se centran en aspectos específicos del juego, como putting, driving y gestión del campo.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Únase a nosotros hoy mismo
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					En Evergreen Hills Golf Club, nos dedicamos a proporcionar una experiencia de golf excepcional que va más allá del juego. Tanto si busca mejorar sus habilidades, relajarse en un ambiente de lujo o formar parte de nuestra vibrante comunidad, Evergreen Hills es su paraíso de golf definitivo.
					</Text>
					<Button
						background="#0438cd"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Contáctenos
					</Button>
				</Box>
				<Image src="https://quixoticstories.com/img/7.jpg" display="block" width="100%" md-order="-1" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});